<div *ngIf="cart" class="lg:mx-auto flex flex-wrap w-full justify-evenly mt-24">
  <div class="w-5/6 lg:w-2/3 lg:px-8 mt-10 lg:mt-0">
    <h1 class="text-2xl my-5">Shopping Cart</h1>
    <div class="w-full flex justify-evenly m-auto flex-wrap">
      <app-cart-list class="w-full"></app-cart-list>
    </div>
  </div>
  <div class="w-5/6 lg:w-1/3 lg:px-7">
    <h1 class="text-2xl my-5">Summary</h1>
    <ul class="text-md">
      <li>Subtotal: {{ cart.subtotal | currency }}</li>
      <li>
        Shipping: {{ shippingComplete ? (cart.shipping.cost | currency) : "$--.--" }}
        <app-tooltip
          [msg]="
            'Your shipping rate is calculated by the carrier and the delivery method. The final shipping cost is then calculated by rate × cart_size. If you are ordering multiple items, feel free to contact us to lower the shipping costs.'
          "
        ></app-tooltip>
      </li>
      <hr class="my-2" />
      <li>
        Total:
        <span class="font-semibold"
          >{{ cart.total | currency }}
          {{ shippingComplete ? "" : "+ Shipping" }}</span
        >
      </li>
      <hr class="my-2" />
    </ul>
    <div></div>
    <div
      *ngIf="!loading && cart.products.length !== 0"
      class="text-center my-5"
    >
      <button
        *ngIf="!selectShippingMode && !shippingComplete"
        type="button"
        [disabled]="cart.products.length === 0"
        (click)="selectShippingMode = true"
        [ngClass]="{ 'bg-lightgreen': cart.products.length > 0 }"
        class="rounded-xl w-50 px-5 py-4 text-center"
      >
        Select Shipping
      </button>
    </div>
    <div *ngIf="selectShippingMode">
      <h1>Choose your preferred shipping method.</h1>
      <button class="my-1 w-full border border-gray-600 rounded py-2 focus:outline-none" [ngClass]="{'bg-red-600': shippingMethod ==='pickup'}" (click)="onChooseShippingType('pickup')" type="button">PICKUP</button>
      <button class="my-1 w-full border border-gray-600 rounded py-2 focus:outline-none" [ngClass]="{'bg-red-600': shippingMethod ==='shipping'}" (click)="onChooseShippingType('shipping')" type="button">SHIPPING</button>
    </div>
    <app-shipping-form
      *ngIf="selectShippingMode && shippingMethod==='shipping'"
      (formComplete)="onShippingComplete()"
    ></app-shipping-form>
    <div class="flex justify-center">
      <button
        *ngIf="shippingComplete || shippingMethod=='pickup'"
        type="button"
        [disabled]="cart.products.length === 0"
        (click)="authorizeCart()"
        [ngClass]="{ 'bg-lightgreen': cart.products.length > 0 , 'bg-gray-400': cart.products.length <= 0}"
        class="rounded-xl w-50 px-5 py-4 text-center"
      >
        Checkout
      </button>
    </div>

    <div *ngIf="loading" class="flex justify-center">
      <app-loading-spinner></app-loading-spinner>
    </div>
    <div class="text-red-500" *ngIf="errors">
      <ul class="list-inside list-disc">
        There was an error processing your order as a result of these items:
        <li *ngFor="let prod of errorProducts">
          {{ prod.name }}
        </li>
      </ul>
    </div>
  </div>
</div>
<div *ngIf="!cart">Cart is loading...</div>
