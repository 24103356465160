<div class="background">
  <div class="banner">
    <div class="flex text-center mx-auto h-full justify-center">
      <h1 class="self-center text-4xl font-extrabold z-10">
        FREQUENTLY ASKED QUESTIONS
      </h1>
    </div>
  </div>

  <h2 class="text-center">Payment and Refunds</h2>
  <section class="my-5">
    <div class="card">
      <h3>What forms of payment do you offer?</h3>
      <p>For pickup orders, we accept Venmo, PayPal, Zelle, or cash.</p>
      <p>
        All online purchases are done through PayPal, which supports card
        payments from Visa, MasterCard, American Express, and Discover. If you would like to do an online purchase through Venmo or Zelle, please send us an email.</p>
    </div>

    <div class="card">
      <h3>What is your refund policy?</h3>
      <p>Once the package has been shipped, we do not usually allow refunds. Anytime before that, we will gladly offer you a refund if you change your mind!</p>
      <p>If there was a problem with the shipping, see the next question on this page.</p>
      <p style="font-style: italic; color: #ABABAB">We send out our packages on Monday and Tuesday mornings.</p>
    </div>
  
    <div class="card">
      <h3>My package arrived damaged, can I get a refund?</h3>
      <p>We try out best to carefully package all orders to prevent them from getting damaged on delivery. However, if your order comes in and it is severely damaged, please send us an email with an image of the package.</p>
      <p>If we believe it is our fault, we will gladly refund you. If it is the fault of the shipping carrier, we will file a claim to get your money back.</p>
    </div>
    
  </section>

  <h2 class="text-center">Shipping and Pickup</h2>
  <section class="my-5">
    <div class="card">
      <h3>Can I pickup?</h3>
      <p>Yes! If you already paid through PayPal, we will gladly offer either a cash or digital refund when you pickup your order.</p>
      <p>Since we do not have a storefront, we kindly ask that you send us the dates and times that you can travel to Alhambra, CA.</p>
    </div>
 
    <div class="card">
      <h3>Where are the plants being shipped from?</h3>
      <p>We are based in Alhambra, CA!</p>
    </div>

    <div class="card">
      <h3>When do you ship?</h3>
      <p>Shipments are usually sent on either Monday or Tuesday morning.</p>
      <p>
        This is done to ensure that plants spend as little time as possible in
        shipping facilities.
      </p>
    </div>

    <div class="card">
      <h3>Do you ship outside the United States?</h3>
      <p>
        Currently, we can only ship to locations within the United States. In
        the past, we have been able to ship succuluents to U.S territories.
      </p>
    </div>

    <div class="card">
      <h3>Can I choose the shipping carrier and/or delivery method?</h3>
      <p>
        Currently, you can select between USPS or UPS for the shipping carriers.
        You can select between Overnight or Two-Day shipping for both carriers.
      </p>
    </div>
 
    <div class="card">
      <h3>
        Can I request for the shipment to be delayed?
      </h3>
      <p>
        We will gladly hold on to any plants that you have purchased until you
        can safely receive your new plants! Just send an email to fe@626plants.com.
      </p>
    </div>
  </section>

  <h2>General Questions</h2>
  <section class="my-5">
    <div class="card">
      <h3>
        Do you grow your own plants.
      </h3>
      <p>
        Many of the Monsterra's are imported, then we take care of them until they are ready to be given a new home!
      </p>
    </div>

    <div class="card">
      <h3>
        Do you have a storefront?
      </h3>
      <p>No, we are a home-based business.</p>
    </div>

    <div class="card">
      <h3>How can I contact you?</h3>
      <p>We have a contact form on our website, but you can also email me at fe@626plants.com</p>
    </div>

    </section>
</div>
