<nav class="font fixed top-0 z-20 w-full">
  <div class="py-3 flex bg-green justify-between px-6">
    <!-- Cart Icon -->
    <a
      class="self-center"
      [routerLink]="['/cart']"
      routerLinkActive="router-link-active"
    >
      <svg
        height="28"
        viewBox="0 0 48 48"
        width="28"
        xmlns="http://www.w3.org/2000/svg"
        class="inline relative top-0"
      >
        <path
          fill="white"
          d="M14 36c-2.21 0-3.98 1.79-3.98 4s1.77 4 3.98 4 4-1.79 4-4-1.79-4-4-4zM2 4v4h4l7.19 15.17-2.7 4.9c-.31.58-.49 1.23-.49 1.93 0 2.21 1.79 4 4 4h24v-4H14.85c-.28 0-.5-.22-.5-.5 0-.09.02-.17.06-.24L16.2 26h14.9c1.5 0 2.81-.83 3.5-2.06l7.15-12.98c.16-.28.25-.61.25-.96 0-1.11-.9-2-2-2H10.43l-1.9-4H2zm32 32c-2.21 0-3.98 1.79-3.98 4s1.77 4 3.98 4 4-1.79 4-4-1.79-4-4-4z"
        />
        <path d="M0 0h48v48H0z" fill="none" />
      </svg>
      <!-- Cart Size Notification -->
      <div
        *ngIf="cartSize !== 0"
        class="
          text-white text-sm
          float-right
          inline-block
          bg-red-500
          h-6
          w-6
          flex
          relative
          text-center
          rounded-full
          items-center
          justify-center
          top-3.5
          right-3.5
        "
      >
        {{ cartSize }}
      </div>
    </a>
    <!-- Hamburger Menu Icon -->
    <ul #hamburger class="p-2 cursor-pointer">
      <li class="h-0.5 w-5 border-2 my-1 border-white rounded bg-white"></li>
      <li class="h-0.5 w-5 border-2 my-1 border-white rounded bg-white"></li>
      <li class="h-0.5 w-5 border-2 my-1 border-white rounded bg-white"></li>
    </ul>
  </div>

  <!-- Dropdown Menu -->
  <div *ngIf="dropdownVisible" class="bg-green rounded-b-2xl absolute w-full">
    <ul class="text-white">
      <li
        routerLinkActive="bg-artichoke text-black"
        [routerLinkActiveOptions]="{ exact: true }"
        class="text-center py-4 cursor-pointer"
        [routerLink]="['/']"
      >
        Home
      </li>
      <li
        routerLinkActive="bg-artichoke text-black"
        class="text-center py-4 cursor-pointer"
        [routerLink]="['/shop']"
      >
        Shop
      </li>
      <li
        routerLinkActive="bg-artichoke text-black"
        class="text-center py-4 cursor-pointer"
        [routerLink]="['/faq']"
      >
        FAQ
      </li>
      <li
        routerLinkActive="bg-artichoke text-black"
        class="text-center py-4 cursor-pointer rounded-b-2xl"
        [routerLink]="['/contact']"
      >
        Contact
      </li>
    </ul>
  </div>
</nav>
