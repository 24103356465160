<div class="wrapper">
    <div class="absolute inset-9 flex justify-center">
        <div class="self-center text-3xl md:text-5xl z-10 text-white title">
            <div class="mx-auto flex justify-between w-5/6">
                <h2>6</h2>
                <h2>2</h2>
                <h2>6</h2>
            </div>
            <h1 class="text-7xl md:text-9xl font-extrabold">PLANTS</h1>
            
        </div>
    </div> 
    
    <div class="banner">
        <div class="banner-card card-1"></div>
        <div class="banner-card card-2"></div>
        <div class="banner-card card-3"></div>
        <div class="banner-card card-4"></div>
    </div>
</div>