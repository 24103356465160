<header></header>
<div class="flex justify-center align-items-center">
  <div class="popup" [@popupState]="state">
    <h3>{{ popupMessage }}</h3>
  </div>
</div>
<div [ngClass]="{ overlay: enlargedImageUrl, hidden: !enlargedImageUrl }">
      <img [src]="enlargedImageUrl" />
    <app-close-button
      (click)="enlargedImageUrl = null"
      class="
        absolute
        float-right
        right-7
        top-7
        z-40
        bg-white
        rounded-full
        p-2.5
        cursor-pointer
      "
    ></app-close-button>
</div>
<div *ngIf="product; then thenBlock; else elseBlock"></div>
<ng-template #thenBlock>
  <div class="wrapper">
    <div *ngIf="!hasScrolled" id="mobileTitle">
      <h1>{{ product.name }}</h1>
      <h3 *ngIf="!product.sold">{{ product.price | currency }}</h3>
      <h3 *ngIf="product.sold" style="font-style: italic">Sold</h3>
    </div>
    <img
      (click)="enlargedImageUrl = selectedImageUrl"
      class="preview"
      [src]="selectedImageUrl"
    />
    <div class="gallery">
      <img
        *ngFor="let image of product.images"
        [ngClass]="{ selected: image === selectedImageUrl }"
        [src]="image"
        (click)="onImageClick(image)"
      />
    </div>
    <div class="infoColumn">
      <div class="nameBlock">
        <h1>{{ product.name }}</h1>
        <h3 *ngIf="product.status==='reserved'" class="italic">Reserved</h3>
        <h3 *ngIf="!product.sold && !product.status==='reserved'">{{ product.price | currency }}</h3>
        <h3 *ngIf="product.sold" class="italic">Sold</h3>
      </div>
      <div>
        <p style="font-weight: 600; font-size: 1.25rem">Description</p>
        <p>{{ product.description }}</p> 
      </div>
      <button
        [disabled]="product.sold || product.status==='reserved'"
        [ngClass]="{
          disabled: product.sold || product.status==='reserved',
          'bg-pink': !product.sold
        }"
        class="bg-lightgreen rounded-full w-full mt-8 py-3"
        (click)="addToCart()"
      >
        Add to Cart
      </button>
    </div>
  </div>
</ng-template>
<ng-template #elseBlock>
  <h2 class="text-center">The product you searched for could not be found.</h2>
</ng-template>