<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <p class="font-semibold">Select Carrier</p>
    <select name="carrier" formControlName="carrier">
      <option value="usps">USPS</option>
      <option value="ups">UPS</option>
    </select>
    <p class="font-semibold mt-2.5">Select Shipping Method</p>
    <div class="block mb-2.5">
      <input
        type="radio"
        id="overnight"
        name="method"
        formControlName="method"
        value="overnight"
      />
      <label for="overnight">Overnight ($55/item)</label>
    </div>
    <div class="block my-2.5">
      <input
        type="radio"
        id="two-day"
        name="method"
        value="two-day"
        formControlName="method"
      />
      <label for="two-day">Two-Day ($30/item)</label>
    </div>
    <hr />
    <p class="font-semibold">Require Signature</p>
    <div *ngIf="form.value['carrier'] == 'ups'">
      <div class="block mb-2.5">
        <input
          type="radio"
          id="yes"
          name="signature"
          formControlName="signature"
          value="true"
        />
        <label for="yes">Yes (+$5)</label>
      </div>
      <div class="block mb-2.5">
        <input
          type="radio"
          id="no"
          name="signature"
          formControlName="signature"
          value="false"
        />
        <label for="no">No</label>
      </div>
    </div>
    <div *ngIf="form.value['carrier'] == 'usps'">
      <div class="block mb-2.5">
        <input
          type="radio"
          id="yes"
          name="signature"
          formControlName="signature"
          value="true"
        />
        <label for="yes">Yes</label>
      </div>
      <div class="block mb-2.5">
        <input
          type="radio"
          id="no"
          name="signature"
          formControlName="signature"
          value="false"
        />
        <label for="no">No</label>
      </div>
  </div>

  <div class="flex justify-center">
    <button
      type="submit"
      class="rounded-xl w-50 px-5 py-4 text-center bg-lightgreen mx-auto"
    >
      Proceed to Checkout
    </button>
  </div>
</form>
