<div class="flex flex-col my-8">
  <img [src]="shopItem.images[0]" [routerLink]="['/shop', shopItem.id]" />
  <div>
    <h1 class="font-semibold">{{ shopItem.name }}</h1>
    <div>
      <h2 *ngIf="shopItem.sold" class="italic">Sold</h2>
      <h2 *ngIf="!shopItem.sold && shopItem.status!=='reserved'">{{ shopItem.price | currency }}</h2>
      <h2 *ngIf="shopItem.status==='reserved'" class="italic">Reserved</h2>
    </div>
  </div>
</div>
