<form (ngSubmit)="onSubmit()" #f="ngForm">
  <div [ngClass]="{'hidden': isBasicInfoCompleted}" >
    <label for="name">Name<span class="text-red-600">*</span></label>
    <input
      class="w-full"
      type="text"
      ngModel
      #name="ngModel"
      required
      name="name"
      [ngClass]="{ 'border-red-500': (name.touched && !name.valid) || !isFormValid}"
    />
    <p *ngIf="name.touched && !name.valid" class="text-red-600">
      Please enter your name
    </p>

    <label for="email">Email<span class="text-red-600">*</span></label>
    <input
      class="w-full"
      type="text"
      ngModel
      #email="ngModel"
      email
      required
      name="email"
      [ngClass]="{ 'border-red-500': (email.touched && !email.valid) || !isFormValid}"
    />
    <p *ngIf="email.touched && !email.valid" class="text-red-600">
      Please enter a valid email address
    </p>

    <label for="phone">Phone Number<span class="text-red-600">*</span></label>
    <input
      class="w-full"
      type="tel"
      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
      ngModel
      #phone="ngModel"
      required
      name="phone"
      [ngClass]="{ 'border-red-500': (phone.touched && !phone.valid) || !isFormValid}"
    />
    <small>Format: 123-456-7890</small>
    <p *ngIf="phone.touched && !phone.valid" class="text-red-600">
      Please enter a valid phone number
    </p>
    <div class="flex justify-center">
      <button
        class="rounded-xl w-50 my-1 px-5 py-4 text-center bg-lightgreen"
        (click)="onContinue()"
        type="button"
      >
        Continue
      </button>
    </div>
  </div>

  <div *ngIf="isBasicInfoCompleted">
    <label for="dates"
      >Available Dates<span class="text-red-600">*</span></label
    >
    <h2 class="text-gray-400 italic ">
      Since we are a home business, we request that you leave available dates
      and times that you can pickup from us. We are located in Alhambra, CA.
      Once we get your information, we will send a follow up email within 24hrs.
      Thanks for your understanding!
    </h2>
    <textarea
      class="w-full resize-y"
      ngModel
      #dates="ngModel"
      required
      name="dates"
      [ngClass]="{ 'border-red-500': dates.touched && !dates.valid }"
    ></textarea>

    <p *ngIf="dates.touched && !dates.valid" class="text-red-600">
      Please list a few dates and times you can pickup from Alhambra, CA.
    </p>
    <div class="flex justify-center">
      <button
        *ngIf="!loading"
        class="
          col-span-1
          md:col-span-2
          bg-blue-600
          px-4
          py-3
          my-1
          rounded
          w-60
          justify-self-center
          text-white
        "
        type="submit"
      >
        Submit
      </button>
    </div>
  </div>
</form>
<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div [ngClass]="{'hidden': !enableOverlay, 'flex': enableOverlay}" class="overlay">
  <div class="overlay-card">
    <h1>Your items have been reserved! We will send you a follow up email within the next 24 hours!</h1>
    <p>Thanks for your support, we hope to see you soon!</p>
    <button
        class="
          col-span-1
          md:col-span-2
          bg-blue-600
          px-4
          py-3
          mb-3
          mt-8
          rounded
          w-60
          text-white
          self-center
        "
        type="button"
        (click)="onHome()"
      >
        Return Home
      </button>
  </div>
</div>