<div *ngFor="let product of products;">
  <div class="flex flex-row justify-between my-5">
    <div class="flex flex-row">
      <img
        [src]="product.images[0]"
        class="w-24 h-24 object-cover"
        alt="product-image"
      />
      <div class="px-5">
        <h2 [routerLink]="['/shop', product.id]" class="font-semibold cursor-pointer">{{ product.name }}</h2>
        <p class="font-thin">{{ product.price | currency }}</p>
        <p class="font-thin">Quantity x{{ product.quantity }}</p>
      </div>
    </div>
    <app-close-button (click)="removeFromCart(product.id)" class="self-center"></app-close-button>
  </div>
  <hr/>
</div>
<div *ngIf="products.length===0">
  Cart is empty
</div>
