<div class="container bg-gray-50 p-10 rounded-xl">
  <form
    (ngSubmit)="onSubmit()"
    #f="ngForm"
    class="grid grid-cols-1 md:grid-cols-2 justify-center gap-6"
  >
    <div>
      <label for="name">Name<span class="text-red-600">*</span></label>
      <input
        class="w-full"
        type="text"
        ngModel
        #name="ngModel"
        required
        name="name"
        [ngClass]="{ 'border-red-500': name.touched && !name.valid }"
      />
      <p *ngIf="name.touched && !name.valid" class="text-red-600">
        Please enter your name
      </p>
    </div>

    <div>
      <label for="phone">Phone Number</label>
      <input class="w-full" type="text" ngModel name="phone" />
    </div>

    <div>
      <label for="email">Email<span class="text-red-600">*</span></label>
      <input
        class="w-full"
        type="text"
        ngModel
        #email="ngModel"
        required
        email
        name="email"
        [ngClass]="{ 'border-red-500': email.touched && !email.valid }"
      />
      <p *ngIf="email.touched && !email.valid" class="text-red-600">
        Please enter a valid email
      </p>
    </div>

    <div>
      <label for="company">Company Name</label>
      <input class="w-full" type="text" ngModel name="company" />
    </div>

    <div class="col-span-1 md:col-span-2">
      <label for="message">Message<span class="text-red-600">*</span></label>
      <textarea
        class="w-full resize-y"
        ngModel
        #message="ngModel"
        required
        name="message"
        [ngClass]="{ 'border-red-500': message.touched && !message.valid }"
      ></textarea>
      <p
        *ngIf="message.touched && !message.valid"
        class="text-red-600 inline-block"
      >
        Please enter a message
      </p>
    </div>

    <button
      *ngIf="!loading"
      class="
        col-span-1
        md:col-span-2
        bg-blue-600
        px-4
        py-3
        rounded
        w-60
        justify-self-center
        text-white
      "
      type="submit"
    >
      Submit
    </button>
    <app-loading-spinner
      *ngIf="loading"
      class="flex justify-center w-full col-span-1 md:col-span-2"
    ></app-loading-spinner>
  </form>
</div>
