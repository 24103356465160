<div class="font w-full h-24 fixed bg-white border-b-2 border-green top-0 nav-bg"
[style.opacity]="bgOpacity">

</div>
<nav
  class="font h-24 fixed top-0 z-20"
  [style.color]="getColor()"
>
  <div class="font-bold text-2xl">626Plants</div>
  <ul class="nav-buttons">
    <li>
      <a
        class="p-2"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['']"
        >Home</a
      >
    </li>
    <li>
      <a class="p-2" [routerLink]="['/shop']" routerLinkActive="active">Shop</a>
    </li>
    <li>
      <a class="p-2" [routerLink]="['/faq']" routerLinkActive="active">FAQ</a>
    </li>
    <li>
      <a class="p-2" [routerLink]="['/contact']" routerLinkActive="active"
        >Contact</a
      >
    </li>
  </ul>
  <div
    [routerLink]="['/cart']"
    routerLinkActive="router-link-active"
    class="socials cursor-pointer w-16"
  >
    <a>
      <svg
        height="48"
        viewBox="0 0 48 48"
        width="48"
        xmlns="http://www.w3.org/2000/svg"
        [style.fill]="getColor()"
      >
        <path
          d="M14 36c-2.21 0-3.98 1.79-3.98 4s1.77 4 3.98 4 4-1.79 4-4-1.79-4-4-4zM2 4v4h4l7.19 15.17-2.7 4.9c-.31.58-.49 1.23-.49 1.93 0 2.21 1.79 4 4 4h24v-4H14.85c-.28 0-.5-.22-.5-.5 0-.09.02-.17.06-.24L16.2 26h14.9c1.5 0 2.81-.83 3.5-2.06l7.15-12.98c.16-.28.25-.61.25-.96 0-1.11-.9-2-2-2H10.43l-1.9-4H2zm32 32c-2.21 0-3.98 1.79-3.98 4s1.77 4 3.98 4 4-1.79 4-4-1.79-4-4-4z"
        />
        <path d="M0 0h48v48H0z" fill="none" />
      </svg>
    </a>
    <div
      *ngIf="cartSize !== 0"
      class="
        float-right
        bg-red-500
        h-6
        w-6
        flex
        text-sm
        relative
        text-white text-center
        rounded-full
        items-center
        justify-center
        top-3.5
        right-6
      "
    >
      {{ cartSize }}
    </div>
  </div>
</nav>
